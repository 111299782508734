import { FC, ReactElement } from 'react';
import { isEmpty } from 'lodash';

import { MainDropdown } from '../MainDropdown';
import { Item } from '../MainDropdown/types';

import styles from './IntroBlock.module.scss';

export type Classes = 'inverted' | 'underline' | 'videos' | 'with-margin';

export interface Props {
    superTitle?: string;
    title: string | ReactElement;
    description?: string;
    additionalClasses?: Classes[];
    dropDownItems?: Item[];
    onChangeDropdown?: (selection: Item) => Promise<void>;
}
export const IntroBlock: FC<Props> = ({
    superTitle,
    title,
    description,
    additionalClasses = [],
    dropDownItems = [],
    onChangeDropdown,
}) => {
    const classes = [styles.IntroBlock];
    additionalClasses?.forEach((_class) => {
        classes.push(styles[_class]);
    });
    return (
        <div className={classes.join(' ')}>
            <h3>{superTitle}</h3>
            <h1>{title}</h1>
            {!isEmpty(dropDownItems) ? (
                <span className={styles['main-drop-holder']}>
                    <MainDropdown onChangeDropdown={onChangeDropdown} items={dropDownItems} />
                </span>
            ) : null}
            {description ? (
                <>
                    <hr />
                    <p className={styles.description}>{description}</p>
                </>
            ) : null}
        </div>
    );
};
